// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// @import url('https://fonts.googleapis.com/css2?family=Carattere&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Carattere&family=Fleur+De+Leah&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons);
@import url('https://fonts.googleapis.com/css2?family=Niconne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

//@import '~@fortawesome/fontawesome-free/scss/fontawesome';
//@import '~@fortawesome/fontawesome-free/scss/regular';
//@import '~@fortawesome/fontawesome-free/scss/solid';
//@import '~@fortawesome/fontawesome-free/scss/brands';


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


